<template>
  <div class="profile">
    <h1>Profile</h1>
    <div class="profile-wrapper">
      <h2>Basic Info</h2>
      <div class="input-div">
        <h3>Full name</h3>
        <input type="text" :class="[disableNameField ? '': 'edit']"
        :disabled="disableNameField" v-model="userInfo.name">
        <svg class="edit" v-if="disableNameField" @click="disableNameField = false">
          <use v-bind="{'xlink:href':'#edit'}" />
        </svg>
        <div class="button-wrapper" v-if="!disableNameField">
          <button class="btn-style-common outlined medium" @click="disableNameField = true">Cancel</button>
          <button class="btn-style-common medium" @click="editDetails('name')">Confirm</button>
        </div>
      </div>
      <div class="input-div">
        <h3>Company</h3>
        <input type="text" :class="[disableCompanyField ? '': 'edit']"
         :disabled="disableCompanyField" v-model="userInfo.company">
        <svg class="edit" v-if="disableCompanyField" @click="disableCompanyField = false">
          <use v-bind="{'xlink:href':'#edit'}" />
        </svg>
        <div class="button-wrapper" v-if="!disableCompanyField">
          <button class="btn-style-common outlined medium" @click="disableCompanyField = true">Cancel</button>
          <button class="btn-style-common medium" @click="editDetails('company')">Confirm</button>
        </div>
      </div>
      <div class="input-div email">
        <h3>Work Email</h3>
        <svg>
          <use v-bind="{'xlink:href':'#lock-icon'}" />
        </svg><p>{{user.email}}</p>
      </div>
      <h2>Password</h2>
      <a :href="cricketHost+'/v5/user/forgot-password'">Change</a>
      <div class="input-div email">
        <h3>Current Password</h3>
        <p>*************</p>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Config from '@/config';
import { User } from '@/store/modules/user';

export class UserDetails {
  constructor() {
    this.name = null;
    this.company = null;
  }

  static typeName() {
    return 'UserDetails';
  }
}

export default {
  computed: {
    ...mapState({
      activeProject: (state) => state.project.activeProject,
      user: (state) => state.user.activeUser,
    }),
  },
  data() {
    return {
      userInfo: new UserDetails(),
      disableNameField: true,
      disableCompanyField: true,
      cricketHost: Config.cricketApiHost,
    };
  },
  mounted() {
    this.updateDetails();
  },
  methods: {
    updateDetails() {
      this.userInfo.name = this.user.name || null;
      this.userInfo.company = this.user.company_name || '-';
    },
    editDetails(type) {
      const details = {};
      details.name = this.userInfo.name;
      details.company = this.userInfo.company;
      details.key = this.user.user;
      return User.editUserDetails(details).then((resp) => {
        if (resp) {
          if (type === 'name') {
            this.disableNameField = true;
          } else {
            this.disableCompanyField = true;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  h1 {
    @include font-styles(
      $type: 'display',
      $size: 'xs',
      $weight: 'bold'
    );
    margin-bottom: size(30);
  }
  .profile-wrapper {
    max-width: size(380);
    h2 {
      @include font-styles(
        $type: 'text',
        $size: 'lg',
        $weight: 'bold'
      );
      margin-bottom: size(14);
      display: inline-block;
      vertical-align: middle;
    }
    a {
      float: right;
      color: var(--global--common-link--Color);
      @include font-styles(
        $type: 'label',
        $size: 'lg',
        $weight: 'normal'
      );
    }
    .input-div {
      background: var(--global--white--Color);
      box-shadow: 0 size(1) size(3) rgba(0, 0, 0, 0.1), 0 size(2) size(1) rgba(0, 0, 0, 0.06),
        0 size(1) size(1) rgba(0, 0, 0, 0.08);
      border-radius: size(8);
      padding: size(15);
      margin-bottom: size(14);
      &.email {
        margin-bottom: size(30);
      }
      h3 {
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'bold'
        );
        margin-bottom: size(6);
      }
      input {
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'normal'
        );
        border: none;
        outline: none;
        width: 95%;
        background: transparent;
        -webkit-text-fill-color: var(--global--main--title--Color);
        opacity: 1;
        &.edit {
          padding: size(14);
          width: 100%;
          border: size(1) solid var(--global--gray--Color);
          border-radius: size(8);
        }
        &:disabled {
          color: var(--global--main--title--Color);
        }
      }
      svg {
        display: inline-block;
        vertical-align: middle;
        width: size(15);
        height: size(15);
        cursor: pointer;
        fill: #777;
        &.edit {
          stroke: #777;
          fill: transparent;
        }
      }
      p {
        @include font-styles(
          $type: 'label',
          $size: 'lg',
          $weight: 'normal'
        );
        color: #777;
        display: inline-block;
        vertical-align: middle;
        padding-left: size(6);
      }
      .button-wrapper {
        margin-top: size(10);
        text-align: right;
        button {
          cursor: pointer;
          &:first-child {
            margin-right: size(10);
          }
        }
      }
    }
  }
}
</style>
